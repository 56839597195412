<template>
	<v-sheet class="member px-3" style="height: calc(100vh - 88px); overflow: auto">
		<v-row>
			<v-col cols="12" class="pt-2 listing-right d-flex justify-content-between">
				<div class="my-auto px-2">
					<v-chip color="cyan" class="ml-2 mr-2" label text-color="white">{{
						orderData.barcode
					}}</v-chip>
				</div>
				<div>
					<v-btn
						v-if="
							(checkout.length > 0 && !checkoutIsSigned) ||
							(checkin.length > 0 && !checkinIsSigned) ||
							(retired.length > 0 && !retiredIsSigned)
						"
						color="blue darken-4 text-white"
						class="mx-2"
						v-on:click="mailDialog = true"
						depressed
						tile
					>
						<v-icon small left>mdi-email</v-icon>
						Send as Email
					</v-btn>

					<v-btn
						v-if="checkout.length > 0 && !checkoutIsSigned"
						color="blue darken-4 text-white"
						class="mx-2"
						depressed
						v-on:click="signatureLink('checkout-approve', orderData.uuid)"
						tile
					>
						<v-icon small left>mdi-email</v-icon>
						Issue Signature Link
					</v-btn>

					<v-btn
						v-if="checkin.length > 0 && !checkinIsSigned"
						color="blue darken-4 text-white"
						class="mx-2"
						depressed
						tile
						v-on:click="signatureLink('checkin-approve', orderData.uuid)"
					>
						<v-icon small left>mdi-email</v-icon>
						Receive Signature Link
					</v-btn>

					<v-btn
						v-if="retired.length > 0 && !retiredIsSigned"
						color="blue darken-4 text-white"
						class="mx-2"
						depressed
						tile
						v-on:click="signatureLink('retired-approve', orderData.uuid)"
					>
						<v-icon small left>mdi-email</v-icon>
						Retired Signature Link
					</v-btn>
					<v-btn :disabled="pageLoading" depressed tile v-on:click="goBack()"> Cancel </v-btn>
				</div>
			</v-col>
		</v-row>
		<!--  -->
		<v-row>
			<v-col cols="12" lg="9" class="custom-checkout-height">
				<perfect-scrollbar :options="{ suppressScrollX: true }" class="scroll" style="position: static">
					<template>
						<div
							v-if="checkout.length > 0"
							class="mx-2 mt-6 mx-md-4 border border-secondary pb-5 pt-2 position-relative"
						>
							<div class="px-3 py-1 font-size-14 cart-item-type orange--text darken-4">Issue</div>
							<div
								class="px-3 py-0 font-size-14 cart-item-type orange--text darken-4"
								style="left: auto; right: 20px"
							>
								<v-icon
									v-on:click="downloadPdf('checkout')"
									:class="pdf_generated(checkoutEForm) ? `red--text` : `gray--text`"
									large
									>mdi-file-pdf-box</v-icon
								>
							</div>
							<template v-for="(row, index) in checkout">
								<v-list :key="index" class="mx-2 mt-4 mx-md-4 border border-secondary" subheader three-line>
									<v-list-item>
										<v-list-item-avatar size="80">
											<ImageTemplate circle :src="assetImage(row)"></ImageTemplate>
										</v-list-item-avatar>
										<v-list-item-content class="col-4" v-if="row">
											<v-list-item-title class="font-size-16">
												<Chip color="blue darken-4 text-white" :text="assetBarcode(row)"></Chip>
											</v-list-item-title>
											<v-list-item-subtitle class="font-size-14">
												<b>{{ assetName(row) }}</b>
											</v-list-item-subtitle>
										</v-list-item-content>
										<v-list-item-content class="col-4" v-if="row">
											<template v-if="!row.inventory">
												<v-list-item-subtitle class="font-size-14">
													<b>Return on :</b>
													<span v-if="row.checkout" class="fw-500 mr-1">
														{{ formatDateTime(row.checkout.return_on) }}</span
													>
												</v-list-item-subtitle>
												<v-list-item-subtitle class="font-size-14">
													<b>Duration :</b>
													<span class="fw-500 mr-1"> 1 Week</span>
												</v-list-item-subtitle>
											</template>
											<v-list-item-subtitle
												v-if="row.checkout && row.checkout.quantity && row.checkout.quantity > 0"
												class="font-size-14"
											>
												<b>Quantity :</b>
												<span class="fw-500 mr-1"> {{ row.checkout.quantity }}</span>
											</v-list-item-subtitle>
										</v-list-item-content>
										<v-list-item-action class="col-4 text-center justify-end" v-if="row">
											<v-list-item-action-text class="font-size-16">
												<template v-if="!row.inventory">
													<v-btn
														v-if="is_checkin_allowed(row)"
														color="blue darken-4 text-white"
														class="mx-2"
														depressed
														tile
														v-on:click="do_checkin(row)"
														><v-icon small left>mdi-share-outline mdi-flip-h</v-icon>Receive</v-btn
													>
													<v-chip v-else label color="green darken-1 text-white" class="pill-user fs-16">
														<v-icon class="mr-1">mdi-check</v-icon> Received
													</v-chip>
													<br />
													<br />
												</template>
												<v-chip
													label
													color="blue darken-4 text-white"
													class="pill-user fs-16"
													v-if="orderData && orderData.member"
												>
													<v-avatar v-if="orderData && orderData.member && orderData.member.image">
														<v-img :src="orderData.member.image"></v-img>
													</v-avatar>
													{{ orderData.member.display_name }}
												</v-chip>
											</v-list-item-action-text>
										</v-list-item-action>
									</v-list-item>
									<table v-if="row.attachment && row.attachment.length" width="100%" class="mx-4">
										<tr>
											<th class="font-size-14 px-3" width="50%" height="30px">Attachments</th>
											<th class="font-size-14" width="50%" height="30px" v-if="row.reason">Reason</th>
										</tr>
										<tr>
											<td valign="top" class="font-size-14" width="50%">
												<v-btn
													v-for="(attachment, index) in row.attachment"
													v-on:click="downloadFile(attachment)"
													:key="index"
													text
													color="blue darken-4"
												>
													<v-icon>mdi-attachment</v-icon> {{ attachment.name }}
												</v-btn>
											</td>
											<td valign="top" class="font-size-14" width="50%" v-if="row.reason">{{ row.reason }}</td>
										</tr>
									</table>
								</v-list>
							</template>
						</div>
						<div
							v-if="checkin.length > 0"
							class="mx-2 mt-4 mx-md-4 border border-secondary pb-5 pt-2 position-relative"
						>
							<div class="px-3 py-1 font-size-14 cart-item-type green--text darken-4">Receive</div>
							<div
								class="px-3 py-0 font-size-14 cart-item-type green--text darken-4"
								style="left: auto; right: 20px"
							>
								<v-icon
									v-on:click="downloadPdf('checkin')"
									:class="
										checkinEForm && checkinEForm[0] && checkinEForm[0].pdf_url ? `red--text` : `gray--text`
									"
									large
									>mdi-file-pdf-box</v-icon
								>
							</div>
							<template v-for="(row, index) in checkin">
								<v-list
									class="mx-2 mt-4 mx-md-4 border border-secondary position-relative"
									subheader
									three-line
									:key="index"
								>
									<v-list-item>
										<v-list-item-avatar size="80" v-if="row.asset">
											<ImageTemplate circle :src="row.asset.image"></ImageTemplate>
										</v-list-item-avatar>
										<v-list-item-avatar size="80" v-if="row.asset_stock">
											<ImageTemplate circle :src="row.asset_stock.image"></ImageTemplate>
										</v-list-item-avatar>
										<v-list-item-content class="col-4" v-if="row">
											<v-list-item-title v-if="row.asset" class="font-size-16">
												<Chip color="blue darken-4 text-white" :text="row.asset.barcode"></Chip>
											</v-list-item-title>
											<v-list-item-title v-if="row.asset_stock" class="font-size-16">
												<Chip color="blue darken-4 text-white" :text="row.asset_stock.barcode"></Chip>
											</v-list-item-title>
											<v-list-item-subtitle v-if="row.asset" class="font-size-14">
												<b>{{ row.asset.name }}</b>
											</v-list-item-subtitle>
											<v-list-item-subtitle v-if="row.asset_stock" class="font-size-14">
												<b>{{ row.asset_stock.name }}</b>
											</v-list-item-subtitle>
										</v-list-item-content>
										<v-list-item-content class="col-4" v-if="row">
											<v-list-item-subtitle class="font-size-14">
												<b>Receive Date :</b>
												<span v-if="row.checkin" class="fw-500 mr-1">
													{{ formatDateTime(row.checkin.checkin_on) }}</span
												>
											</v-list-item-subtitle>
											<v-list-item-subtitle class="font-size-14">
												<b>Comment :</b>
												<span class="fw-500 mr-1"> {{ row.checkin.description }}</span>
											</v-list-item-subtitle>
										</v-list-item-content>
										<v-list-item-action class="col-4" v-if="row">
											<v-list-item-action-text class="font-size-16 mt-2">
												<v-chip
													label
													color="blue darken-4 text-white"
													class="pill-user fs-16"
													v-if="orderData && orderData.member"
												>
													<v-avatar v-if="orderData && orderData.member && orderData.member.image">
														<v-img :src="orderData.member.image"></v-img>
													</v-avatar>
													{{ orderData.member.display_name }}
												</v-chip>
											</v-list-item-action-text>
										</v-list-item-action>
									</v-list-item>
									<table v-if="row.attachment && row.attachment.length" width="100%" class="mx-4">
										<tr>
											<th class="font-size-14 px-3" width="50%" height="30px">Attachments</th>
											<th class="font-size-14" width="50%" height="30px" v-if="row.reason">Reason</th>
										</tr>
										<tr>
											<td valign="top" class="font-size-14" width="50%">
												<v-btn
													v-for="(attachment, index) in row.attachment"
													v-on:click="downloadFile(attachment)"
													:key="index"
													text
													color="blue darken-4"
												>
													<v-icon>mdi-attachment</v-icon> {{ attachment.name }}
												</v-btn>
											</td>
											<td valign="top" class="font-size-14" width="50%" v-if="row.reason">{{ row.reason }}</td>
										</tr>
									</table>
								</v-list>
							</template>
						</div>
						<div
							v-if="retired.length > 0"
							class="mx-2 mt-4 mx-md-4 border border-secondary pb-5 pt-2 position-relative"
						>
							<div class="px-3 py-1 font-size-14 cart-item-type red--text darken-4">Retired</div>
							<div
								class="px-3 py-0 font-size-14 cart-item-type red--text darken-4"
								style="left: auto; right: 20px"
							>
								<v-icon
									v-on:click="downloadPdf('retired')"
									:class="
										retiredEForm && retiredEForm[0] && retiredEForm[0].pdf_url ? `red--text` : `gray--text`
									"
									large
									>mdi-file-pdf-box</v-icon
								>
							</div>
							<template v-for="(row, index) in retired">
								<v-list :key="index" class="mx-2 mt-4 mx-md-4 border border-secondary" subheader three-line>
									<v-list-item>
										<v-list-item-avatar size="80" v-if="row.asset">
											<ImageTemplate circle :src="row.asset.image"></ImageTemplate>
										</v-list-item-avatar>
										<v-list-item-avatar size="80" v-if="row.asset_stock">
											<ImageTemplate circle :src="row.asset_stock.image"></ImageTemplate>
										</v-list-item-avatar>
										<v-list-item-avatar size="80" v-if="row.inventory">
											<ImageTemplate circle :src="row.inventory.image"></ImageTemplate>
										</v-list-item-avatar>
										<v-list-item-content class="col-4" v-if="row">
											<v-list-item-title v-if="row.asset" class="font-size-16">
												<Chip color="blue darken-4 text-white" :text="row.asset.barcode"></Chip>
											</v-list-item-title>
											<v-list-item-title v-if="row.asset_stock" class="font-size-16">
												<Chip color="blue darken-4 text-white" :text="row.asset_stock.barcode"></Chip>
											</v-list-item-title>
											<v-list-item-title v-if="row.inventory" class="font-size-16">
												<Chip color="blue darken-4 text-white" :text="row.inventory.barcode"></Chip>
											</v-list-item-title>
											<v-list-item-subtitle v-if="row.asset" class="font-size-14">
												<b>{{ row.asset.name }}</b>
											</v-list-item-subtitle>
											<v-list-item-subtitle v-if="row.asset_stock" class="font-size-14">
												<b>{{ row.asset_stock.name }}</b>
											</v-list-item-subtitle>
											<v-list-item-subtitle v-if="row.inventory" class="font-size-14">
												<b>{{ row.inventory.name }}</b>
											</v-list-item-subtitle>
										</v-list-item-content>
										<v-list-item-content class="col-4" v-if="row">
											<v-list-item-subtitle class="font-size-14">
												<b>Retire Date :</b>
												<span v-if="row.retired" class="fw-500 mr-1">
													{{ formatDate(row.retired.retire_date) }}</span
												>
											</v-list-item-subtitle>
											<v-list-item-subtitle class="font-size-14">
												<b>Retire Reason :</b>
												<span v-if="row.retired" class="fw-500 mr-1"> {{ row.retired.reason }}</span>
											</v-list-item-subtitle>
										</v-list-item-content>
										<v-list-item-action class="col-4" v-if="row">
											<v-list-item-action-text class="font-size-16 mt-2">
												<v-chip
													label
													color="blue darken-4 text-white"
													class="pill-user fs-16"
													v-if="orderData && orderData.member"
												>
													<v-avatar v-if="orderData && orderData.member && orderData.member.image">
														<v-img :src="orderData.member.image"></v-img>
													</v-avatar>
													{{ orderData.member.display_name }}
												</v-chip>
											</v-list-item-action-text>
										</v-list-item-action>
									</v-list-item>
									<table v-if="row.attachment && row.attachment.length" width="100%" class="mx-4">
										<tr>
											<th class="font-size-14 px-3" width="50%" height="30px">Attachments</th>
											<th class="font-size-14" width="50%" height="30px" v-if="row.reason">Reason</th>
										</tr>
										<tr>
											<td valign="top" class="font-size-14" width="50%">
												<v-btn
													v-for="(attachment, index) in row.attachment"
													v-on:click="downloadFile(attachment)"
													:key="index"
													text
													color="blue darken-4"
												>
													<v-icon>mdi-attachment</v-icon> {{ attachment.name }}
												</v-btn>
											</td>
											<td valign="top" class="font-size-14" width="50%" v-if="row.reason">{{ row.reason }}</td>
										</tr>
									</table>
								</v-list>
							</template>
						</div>
						<div
							v-if="reservation.length > 0"
							class="mx-2 mt-6 mx-md-4 border border-secondary pb-3 position-relative"
						>
							<div class="px-3 py-1 font-size-14 cart-item-type blue--text darken-4">Reservation</div>
							<template v-for="(row, index) in reservation">
								<v-list :key="index" class="mx-2 mt-4 mx-md-4 border border-secondary" subheader three-line>
									<v-list-item>
										<v-list-item-avatar size="80" v-if="row.asset">
											<ImageTemplate circle :src="row.asset.image"></ImageTemplate>
										</v-list-item-avatar>
										<v-list-item-avatar size="80" v-if="row.asset_stock">
											<ImageTemplate circle :src="row.asset_stock.image"></ImageTemplate>
										</v-list-item-avatar>
										<v-list-item-content class="col-4" v-if="row">
											<v-list-item-title v-if="row.asset" class="font-size-16">
												<Chip color="blue darken-4 text-white" :text="row.asset.barcode"></Chip>
											</v-list-item-title>
											<v-list-item-title v-if="row.asset_stock" class="font-size-16">
												<Chip color="blue darken-4 text-white" :text="row.asset_stock.barcode"></Chip>
											</v-list-item-title>
											<v-list-item-subtitle v-if="row.asset" class="font-size-14">
												<b>{{ row.asset.name }}</b>
											</v-list-item-subtitle>
											<v-list-item-subtitle v-if="row.asset_stock" class="font-size-14">
												<b>{{ row.asset_stock.name }}</b>
											</v-list-item-subtitle>
										</v-list-item-content>
										<v-list-item-content class="col-4" v-if="row">
											<v-list-item-subtitle class="font-size-14">
												<b>From :</b>
												<span v-if="row.reservation" class="fw-500 mr-1">
													{{ formatDateTime(row.reservation.reserve_from) }}</span
												>
											</v-list-item-subtitle>
											<v-list-item-subtitle class="font-size-14">
												<b>To :</b>
												<span v-if="row.reservation" class="fw-500 mr-1">
													{{ formatDateTime(row.reservation.reserve_to) }}</span
												>
											</v-list-item-subtitle>
											<v-list-item-subtitle class="font-size-14">
												<b>Duration :</b>
												<span class="fw-500 mr-1"> {{ relatedDetails.duration }}</span>
											</v-list-item-subtitle>
										</v-list-item-content>
										<v-list-item-action class="col-4" v-if="row">
											<v-list-item-action-text class="font-size-16 mt-2">
												<v-chip
													label
													color="blue darken-4 text-white"
													class="pill-user fs-16"
													v-if="orderData && orderData.member"
												>
													<v-avatar v-if="orderData && orderData.member && orderData.member.image">
														<v-img :src="orderData.member.image"></v-img>
													</v-avatar>
													{{ orderData.member.display_name }}
												</v-chip>
											</v-list-item-action-text>
										</v-list-item-action>
									</v-list-item>
								</v-list>
							</template>
						</div>
					</template>
				</perfect-scrollbar>
			</v-col>
			<v-col cols="12" lg="3" class="">
				<div
					class="mx-2 py-2 mt-4 mx-md-4 d-flex flex-wrap flex-column justify-content-between border border-secondary h-100"
				>
					<div class="px-2">
						<div class="font-size-16 px-3 py-3 mb-2 blue lighten-5 d-flex">
							<div class="fw-500">Total Issued</div>
							<v-spacer></v-spacer>
							<div
								color=""
								class="text-center px-1 orange white--text rounded-sm"
								label
								style="min-width: 30px"
							>
								{{ checkout.length }}
							</div>
						</div>
						<div class="font-size-16 mb-2 px-3 py-3 blue lighten-5 d-flex" v-if="false">
							<div class="fw-500">Total Reservation</div>
							<v-spacer></v-spacer>
							<div>
								<div
									color=""
									class="text-center px-1 orange white--text rounded-sm"
									label
									style="min-width: 30px"
								>
									{{ reservation.length }}
								</div>
							</div>
						</div>
						<div class="font-size-16 mb-2 px-3 py-3 blue lighten-5 d-flex">
							<div class="fw-500">Total Received</div>
							<v-spacer></v-spacer>
							<div
								color=""
								class="text-center px-1 orange white--text rounded-sm"
								label
								style="min-width: 30px"
							>
								{{ checkin.length }}
							</div>
						</div>
						<div class="font-size-16 mb-2 px-3 py-3 blue lighten-5 d-flex">
							<div class="fw-500">Total Retired</div>
							<v-spacer></v-spacer>
							<div
								color=""
								class="text-center px-1 orange white--text rounded-sm"
								label
								style="min-width: 30px"
							>
								{{ retired.length }}
							</div>
						</div>
					</div>
				</div>
			</v-col>
		</v-row>
		<SendMail
			:mailDialog="mailDialog"
			type="order"
			:typeId="orderData.id"
			:type-uuid="orderData.uuid"
			v-on:close="mailDialog = false"
		>
		</SendMail>
		<template v-if="TCheckInDialog && TCheckInCUUID && TCheckInUUID">
			<CheckinTemplate
				:type="TCheckInType"
				:endpoint="TCheckInEndPoint"
				:type-uuid="TCheckInUUID"
				:checkout-uuid="TCheckInCUUID"
				v-on:success="TCheckInDialog = false"
				v-on:close="TCheckInDialog = false"
				:checkin-dialog="TCheckInDialog"
			></CheckinTemplate>
		</template>
	</v-sheet>
</template>
<script>
import ImageTemplate from "@/view/components/Image";
import Chip from "@/view/components/Chip";
import SendMail from "@/view/components/Send-Mail";
import { GetOrder } from "@/core/lib/cart.lib";
import { SET_ERROR } from "@/core/services/store/common.module";
import CheckinTemplate from "@/view/components/CheckinTemplate";
import { filter } from "lodash";
import objectPath from "object-path";

export default {
	name: "Issue",
	components: {
		CheckinTemplate,
		ImageTemplate,
		SendMail,
		Chip,
	},
	data() {
		return {
			TCheckInType: null,
			TCheckInEndPoint: null,
			TCheckInUUID: null,
			TCheckInCUUID: null,
			TCheckInDialog: false,
			dataTab: "checkout",
			orderData: [],
			checkout: [],
			mailDialog: false,
			reservation: [],
			checkin: [],
			retired: [],
			checkoutEForm: null,
			checkinEForm: null,
			retiredEForm: null,
			pageLoading: false,
			deleteDialog: false,
			description: null,
			subject: null,
			cc: [],
			recipient: [],
			recipients: [],
			selectRelateddialog: false,
			deleteButton: false,
			relatedUuid: null,
			uuid: null,
			checkOutUuid: null,
			relatedType: null,
			requestType: null,
			relatedUuidCheckIn: null,
			relatedTypeCheckIn: null,
			relatedEndPointCheckIn: null,
			checkinDialog: false,
			signature: null,
			relatedDetails: {
				barcode: "AS-00004",
				name: "ALIENWARE M15 R6 GAMING LAPTOP",
				pico_barcode: null,
				price_add_stock: 100,
				cost_price_formatted: "$100.00",
				return_on: "30/07/2022  10:23",
				duration: "1 Week",
				checkout_to: "Super Admin",
			},
			deleteUUID: null,
			deleteText: null,
		};
	},
	methods: {
		downloadFile(row) {
			window.open(row.url, "_blank");
		},
		do_checkin(row) {
			console.log({ row });
			const is_asset = objectPath.has(row, "asset.uuid");

			this.TCheckInType = is_asset ? "Asset" : "Asset Stock";
			this.TCheckInEndPoint = is_asset ? "asset" : "asset-stock";
			this.TCheckInUUID = is_asset
				? objectPath.get(row, "asset.uuid")
				: objectPath.get(row, "asset_stock.uuid");
			this.TCheckInCUUID = objectPath.get(row, "checkout.uuid");
			this.TCheckInDialog = true;
		},
		is_checkin_allowed(row) {
			return !+objectPath.get(row, "checkout.has_checkin", 0);
		},
		pdf_generated(row) {
			return objectPath.has(row, "0.pdf_url");
		},
		getOrder() {
			const _this = this;
			GetOrder(this.uuid)
				.then((data) => {
					_this.orderData = data;
					_this.checkout = filter(data.order_items, (row) => {
						return row.type == 2;
					});
					_this.reservation = filter(data.order_items, (row) => {
						return row.type == 1;
					});
					_this.checkin = filter(data.order_items, (row) => {
						return row.type == 3;
					});
					_this.retired = filter(data.order_items, (row) => {
						return row.type == 4;
					});

					_this.checkoutEForm = filter(data.e_form, (row) => {
						return row.type == 2;
					});
					_this.checkinEForm = filter(data.e_form, (row) => {
						return row.type == 3;
					});
					_this.retiredEForm = filter(data.e_form, (row) => {
						return row.type == 4;
					});
				})
				.catch((error) => {
					this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		downloadPdf(type) {
			if (
				type == "checkout" &&
				this.checkoutEForm &&
				this.checkoutEForm[0] &&
				this.checkoutEForm[0].pdf_url
			) {
				window.open(this.checkoutEForm[0].pdf_url, "_blank");
			}
			if (
				type == "checkin" &&
				this.checkinEForm &&
				this.checkinEForm[0] &&
				this.checkinEForm[0].pdf_url
			) {
				window.open(this.checkinEForm[0].pdf_url, "_blank");
			}
			if (
				type == "retired" &&
				this.retiredEForm &&
				this.retiredEForm[0] &&
				this.retiredEForm[0].pdf_url
			) {
				window.open(this.retiredEForm[0].pdf_url, "_blank");
			}
		},
		signatureLink(routerName, uuid) {
			let routeData = this.$router.resolve({ name: routerName, params: uuid });
			window.open(routeData.href, "_blank");
		},
		assetImage(data) {
			if (data.asset) {
				return data.asset.image;
			} else if (data.asset_stock) {
				return data.asset_stock.image;
			} else if (data.inventory) {
				return data.inventory.image;
			}
		},
		assetBarcode(data) {
			if (data.asset) {
				return data.asset.barcode;
			} else if (data.asset_stock) {
				return data.asset_stock.barcode;
			} else if (data.inventory) {
				return data.inventory.barcode;
			}
		},
		assetName(data) {
			if (data.asset) {
				return data.asset.name;
			} else if (data.asset_stock) {
				return data.asset_stock.name;
			} else if (data.inventory) {
				return data.inventory.name;
			}
		},
	},
	computed: {
		checkoutIsSigned() {
			if (this.checkout && this.checkout[0] && this.checkout[0].is_signed) {
				return true;
			} else {
				return false;
			}
		},
		checkinIsSigned() {
			if (this.checkin && this.checkin[0] && this.checkin[0].is_signed) {
				return true;
			} else {
				return false;
			}
		},
		retiredIsSigned() {
			if (this.retired && this.retired[0] && this.retired[0].is_signed) {
				return true;
			} else {
				return false;
			}
		},
	},
	mounted() {
		const { uuid } = this.$route.params;
		this.uuid = uuid;
		if (!this.uuid) {
			this.goBack();
		}
		this.getOrder();
	},
};
</script>
<style scoped></style>
